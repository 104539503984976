import { Slider } from '../modules/slider';

const sections = document.querySelectorAll('.products-slider');

if (sections.length > 0) {
    sections.forEach(section => {
        const slider = new Slider({
            el: section,
            loop: false,
            withArrows: true,
            blockLastSlides: true,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    marginRight: 8,
                },
                767: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                    marginRight: 24,
                },
            },
        });
    });
}
